import React from 'react'
import NavbarAfterAuth from '../../components/navbar/navbarAfterAuth'
import Panier from '../../components/Panier/Panier'
import Footer from '../../components/Footer/Footer'

function PanierPage () {
  return (
    <div>
        <NavbarAfterAuth/>
        <Panier/>
        <Footer/>
    </div>
  )
}

export default PanierPage