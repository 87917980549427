import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import './accueil.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

function AccueilCarousel() {
  return (
    <Carousel interval={3000}>
        <Carousel.Item>
            <div className='accueilFullContainer img1'>
                <div className='accueilContainer'>
                    <div className='accueilItemDesc'>
                        <div className='AIDescriptionTitle Animation1'>
                            أبني دارك <span className='accueilSpan'>و خلص بعقلك </span>
                        </div>
                        <div className='AIDescriptionTexte Animation2'>اكتشف مجموعة متنوعة من مواد البناء عالية الجودة، من الإسمنت إلى الدهان، مدعومة بتمويل إسلامي يسهل الوصول إليه. من التصميم إلى التنفيذ، نحن هنا لمرافقتك في كل خطوة من مشروعك. استكشف منتجاتنا وخدماتنا اليوم واجعل رؤيتك حقيقة.</div>
                        <div className='accueilBtnContainer Animation3'><Button className='accueilBtn'>مشروعنا <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='accueilIcon'/></Button></div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className='accueilFullContainer img2'>
                <div className='accueilContainer'>
                    <div className='accueilItemDesc'>
                        <div className='AIDescriptionTitle Animation2'>
                       ! مشروعك، <span className='accueilSpan'>أولويتنا</span>
                        </div>
                        <div className='AIDescriptionTexte Animation3'>حوّل أفكارك إلى واقع، مع حلول مصممة خصيصًا لمشاريعك في مجال البناء.</div>
                        <div className='accueilBtnContainer Animation1'><Button className='accueilBtn'>تواصل معنا <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='accueilIcon'/></Button></div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className='accueilFullContainer img3'>
                <div className='accueilContainer'>
                    <div className='accueilItemDesc'>
                        <div className='AIDescriptionTitle Animation3'>
                              <span className='accueilSpan'>IBENI </span>
                        </div>
                        <div className='AIDescriptionTexte Animation1'>حوّل رؤيتك للبناء إلى واقع مع منصتنا السهلة الاستخدام، التي تقدم مجموعة كاملة من المنتجات والخدمات لكل مرحلة من مراحل مشروعك، من البداية إلى النهاية.</div>
                        <div className='accueilBtnContainer Animation2'><Button className='accueilBtn'>خدماتنا <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='accueilIcon'/></Button></div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
    </Carousel>
  );
}

export default AccueilCarousel;