import React from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './partenarship.css'
import feedbackImg from '../../assets/image/feedback.jpg'
import FooterBottom from '../Footer/FooterBottom';

function Avis () {
  return (
    <div className='AvisFullContainer'>
        <div className='AvisContainer'>
        <img src={feedbackImg} alt='' className='AvisImg'></img>
        <div className='PartenarshipContactSection'>
            <h1>Laissez-nous votre avis</h1>
            <div>Êtes-vous satisfait ?</div>
            <div className='PartenarshipContactForm'>
            <Form className='PartenarshipContactFormContent'>
                <Row className="mb-3 ">
                    <Form.Group className='contactFormInput' as={Col} controlId="formGridNom">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control className='PartenaireInput' type="text" placeholder="Votre Nom" />
                    </Form.Group>
                    <Form.Group className='contactFormInput' as={Col} controlId="formGridPrénom">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control className='PartenaireInput' type="text" placeholder="Votre Numéro Téléphone" />
                    </Form.Group>
                </Row>
                <Row className="mb-3 ">
                    <Form.Group className='contactFormInput' as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='PartenaireInput' type="email" placeholder="Votre email" />
                    </Form.Group>
                    <Form.Group className='contactFormInput' as={Col} controlId="formGridPhone">
                        <Form.Label>Numéro Téléphone</Form.Label>
                        <Form.Control className='PartenaireInput' type="text" placeholder="Votre Numéro Téléphone" />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3 contactFormInput" controlId="exampleForm.ControlComment">
                    <Form.Label>Avis</Form.Label>
                    <Form.Control className='PartenaireInput' as="textarea" rows={7} placeholder='Redigez votre avis ici'/>
                </Form.Group>
                <Form.Group className="mb-3 contactFormInput" controlId="formGridCheck">
                    <Form.Label>Nous recommanderiez-vous à des amis ?</Form.Label>
                    <Form.Check type="radio" label="Oui" name='recommandRes' id='RecommandAccept'/>
                    <Form.Check type="radio" label="Non" name='recommandRes' id='RecommandDeny'/>
                </Form.Group>
                <Form.Group className="mb-3 contactFormInput" controlId="exampleForm.ControlComment">
                    <Form.Label>Souhaitez-vous ajoutez quelque chose ?</Form.Label>
                    <Form.Control className='PartenaireInput' as="textarea" rows={7} placeholder='Redigez votre avis ici'/>
                </Form.Group>
                <Button className='PartenarshipBtn' type="submit">
                    Envoyer
                </Button>
            </Form>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Avis