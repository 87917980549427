import React from 'react'
import CollapsedNavbar from '../../components/navbar/Navbar'
import Partenarship from '../../components/Partenarship/Partenarship';
import FooterBottom from '../../components/Footer/FooterBottom';

function PartenarshipPage () {
  return (
    <div>
        <CollapsedNavbar/>
        <Partenarship/>
        <FooterBottom/>
    </div>
  )
}

export default PartenarshipPage;