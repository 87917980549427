import React from 'react'
import CollapsedNavbar from '../../components/navbar/Navbar'
import Avis from './../../components/Partenarship/Avis';
import FooterBottom from '../../components/Footer/FooterBottom';

function AvisPage () {
  return (
    <div>
        <CollapsedNavbar/>
        <Avis/>
        <FooterBottom/>
    </div>
  )
}

export default AvisPage