import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './service.css';
import VerticallyCenteredModal from '../Modal/VerticallyCenteredModal';
import { useState } from 'react';

function ServiceCard(props) {
  const [modalShow, setModalShow] = useState(false);
  const textModel = "Merci d'accéder à votre compte pour pouvoir bénéficier de nos services . Si vous ne possedez pas d'un compte , c'est le moment de s'inscrire !"
  return (
    <Card style={{ width: '19rem' }} className='serviceCard'>
      <Card.Img variant="top" src={props.serviceImg} className='serviceImg'/>
      <Card.Body className='serviceCardBody'>
        <Card.Title className='serviceCardTitle'>{props.serviceName}</Card.Title>
        <hr class="hr-horizontal bg-primary" style={{height: "2px;"}}/> {/* Ligne horizontale après le titre */}
        <Card.Text className='serviceCardTime'>
            {props.serviceDuration} h
        </Card.Text>
        <Button className='serviceCardBtn' onClick={() => setModalShow(true)}>Réserver</Button>
        <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        TextModel={textModel}
        />
      </Card.Body>
    </Card>
  );
}

export default ServiceCard;
