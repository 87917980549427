import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import IbeniLogo from '../../assets/image/IbeniLogo2.svg';
import Button from 'react-bootstrap/Button';
import './navbarAdmin.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {LogOut} from '../../Api/Api';

function NavbarAdmin() {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
          await LogOut();
          navigate("/");
        } catch (error) {
          console.error("Error during logout:", error);
          // Handle the error here
        }
      };
  return (
    <div className='NavbarLandingContainer'>
      <Navbar collapseOnSelect expand="lg" className=" navbarFullContainer">
        <Container className='navbarContainer'>
          <Navbar.Brand href="#home"><img src={IbeniLogo} alt='' className='logoImg'></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto navbarItemsAdmin">
                  <Nav.Link href="/admin/BoutiqueAdmin" className='navbarItem'>Boutique</Nav.Link>
                  <Nav.Link href="/admin/Commandes" className='navbarItem'>Commandes<FontAwesomeIcon icon={faCartShopping}/></Nav.Link>
                  <Nav.Link href="/"><Button className='NavbarBtn' onClick={handleLogout}> Se Déconnecter</Button></Nav.Link>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarAdmin;


/**
 * 
                <Nav.Link href="/Boutique" className='navbarItem'>Boutique</Nav.Link>
                <Nav.Link href="/Boutique" className='navbarItem'>Dossier à fournir</Nav.Link>
 */