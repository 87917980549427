import React from 'react'
import NavbarAfterAuth from '../../components/navbar/navbarAfterAuth'
import Boutique from '../../components/Boutique/Boutique'
import Footer from '../../components/Footer/Footer'

function BoutiquePage () {
  return (
    <div>
        <NavbarAfterAuth/>
        <Boutique/>
        <Footer/>
    </div>
  )
}

export default BoutiquePage