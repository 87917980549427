import React from "react";
import engine from '../../assets/image/engine.jpg'
import kitchen from '../../assets/image/kitchen1.jpg'
import equipement from '../../assets/image/equipement.webp'
import ServiceCard from "./ServiceCard";

function OurServices() {
    const data =[
        {
            serviceImg:engine,
            serviceName:'gros œuvres : "dalle et béton"',
            serviceDuration:'2'
        },
        {
            serviceImg:kitchen,
            serviceName:'Estimation de matériaux de construction',
            serviceDuration:'3'
        },
        {
            serviceImg:equipement,
            serviceName:'Estimation de realisation de Cuisine',
            serviceDuration:'4'
        },
    ]
  return (
    <div className="OurServicesFullContainer">
        <div className="OurServicesTitle">Nos Services</div>
        <hr className="OurServicesLine"/>
        <div className="OurServicesConatiner">
            {data.map((service, index)=>{
                return(
                    <ServiceCard key={index} serviceImg={service.serviceImg} serviceName={service.serviceName} serviceDuration={service.serviceDuration} />
                )
            })}
        </div>
    </div>
  );
}

export default OurServices;
