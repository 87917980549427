import React, { useRef, useState } from 'react'
import ProductCard from '../Produit/ProductCard'
import produit from '../../assets/image/product1.webp'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { addProductapi } from './../../Api/Api';
import Notifycomposant from '../Notification/Notify';
import './AddProduct.css'
import { useMatiereContext } from "../../Api/context/Context";
import Pagination from '../Pagination/Pagination';

function BoutiqueAdmin () {
    const { products } = useMatiereContext();
    const [show, setShow] = useState(false);
    const [nomProduct , setNomProduct] = useState('');
    const [sKU , setSKU] = useState('');
    const [prix,setPrix] = useState(0);
    const [description, setDescription] = useState('');
    const [notification, setNotification] = useState(null);
    const [file, setFile] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const donnees = products;
  const totalPages = [];
  for (let i = 1; i <= Math.ceil(donnees.length / itemsPerPage); i++) {
    totalPages.push(i);
  }
  const currentItem = donnees.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const inputRef = useRef(null);

  const handleSubmitProductInfo = async (event) => {
    event.preventDefault();

    if (
      !nomProduct ||
      !sKU ||
      !prix ||
      !description ||
      !file 
    ) {
        console.log(nomProduct);
        console.log(sKU);
        console.log(prix);
        console.log(description);
        console.log(file);
      setNotification({
        type: "error",
        message: "Veuillez remplir tous les champs !",
      });
      return;
    }

    try {
      const response = await addProductapi({
        NomProduct: nomProduct,
        Sku: sKU,
        Prix: prix,
        Description: description,
        Picture: file,
      });
      handleNotification("success", response);
    } catch (error) {
      console.error(error.response);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Erreur lors de l'ajout de produit";
      handleNotification("error", errorMessage);
    }
  };


  const handleNotification = (type, message) => {
    setNotification({
      type,
      message,
    });
  };
  return (
    <div className='BoutiqueAdminFullContainer'>
        <div className='BoutiqueAdminContainer'>
            <div>
                <Button className='addProductBtn' onClick={handleShow}>Ajouter Un Produit</Button>
            </div>
            <div className='BoutiqueProducts'>
                {currentItem.map((prod, index)=>{
                    return(
                        <ProductCard id={prod.id} authent={true} key={index} productImg= {prod.Picture} productName={prod.NomProduct} productPrice={prod.Prix}/>
                    )
                })}
            </div>
            <div style={{ direction: "rtl" }}>
                    <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    ></Pagination>
          </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Ajouter un produit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmitProductInfo} className='addProductForm' >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nom du produit :</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            autoFocus
                            name="NomProduct"
                            value={nomProduct}
                            onChange={(e)=>setNomProduct(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>SKU :</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="SKU"
                            value={sKU}
                            name="SKU"
                            onChange={(e)=>setSKU(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Prix : </Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Prix"
                            value={prix}
                            name="Prix"
                            onChange={(e)=>setPrix(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description : </Form.Label>
                        <Form.Control
                            type="textarea"
                            placeholder="Description"
                            value={description}
                            name="Description"
                            onChange={(e)=>setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Image de produit</Form.Label>
                                <div className="pack-add-packImg">
                                    {file ? (
                                        <div className="PackImage">{file.name}</div>
                                    ) : (
                                        null
                                    )}
                                    <button
                                        type="button"
                                        onClick={() => inputRef.current.click()}
                                        className="UploadPackImage"
                                    >
                                        Upload Image
                                    </button>
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .webp"
                                        ref={inputRef}
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                </div>
                            </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
        {notification && (
        <Notifycomposant
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  )
}

export default BoutiqueAdmin