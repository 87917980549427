import React from 'react'
import './partenarship.css'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FooterBottom from '../Footer/FooterBottom';

function Partenarship () {
  return (
    <div className='PartenarshipFullConatiner'>
        <div className='PartenarshipConatiner'>
            <div className='PartenarshipImages'>
                <div className='PartenarshipImg1' >
                    <div className='PartenarshipImgC'>
                        <div className='PartenarshipTitre'>CONSTRUCTION</div>
                        <div className='PartenarshipDetail'>vous êtes distributeur de matériaux</div>
                    </div>
                </div>
                <div className='PartenarshipImg2' >
                    <div className='PartenarshipImgC'>
                        <div className='PartenarshipTitre'>RÉNOVATION</div>
                        <div className='PartenarshipDetail'>vous offrez des services de rénovation / construction</div>
                    </div>
                </div>
            </div>
            <div className='PartenarshipContactSection'>
                <h1>Contactez nous</h1>
                <div>Voulez-vous booster vos services ou bien gagnez encore plus de clients ? vous êtes dans la bonne adresse !</div>
                <div className='PartenarshipContactForm'>
                <Form className='PartenarshipContactFormContent'>
                    <Form.Group className="mb-3 contactFormInput" controlId="formGridNom">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control className='PartenaireInput' placeholder="Votre Nom" />
                    </Form.Group>

                    <Form.Group className="mb-3 contactFormInput" controlId="formGridPrenom">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control className='PartenaireInput' placeholder="Votre Prénom" />
                    </Form.Group>
                    <Row className="mb-3 ">
                        <Form.Group className='contactFormInput' as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='PartenaireInput' type="email" placeholder="Votre email" />
                        </Form.Group>

                        <Form.Group className='contactFormInput' as={Col} controlId="formGridPhone">
                        <Form.Label>Numéro Téléphone</Form.Label>
                        <Form.Control className='PartenaireInput' type="text" placeholder="Votre Numéro Téléphone" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 ">
                        <Form.Group as={Col} className="mb-3 contactFormInput" controlId="formGridCompany">
                            <Form.Label>Nom de l'entreprise</Form.Label>
                            <Form.Control className='PartenaireInput' placeholder="Nom de votre entreprise" />
                        </Form.Group>

                        <Form.Group className='contactFormInput' as={Col} controlId="formGridState">
                        <Form.Label>Secteur d'activité</Form.Label>
                        <Form.Select className='PartenaireInput' defaultValue="selectionner...">
                            <option>Distributeur matériaux</option>
                            <option>Fournisseur de service de rénovation/construction</option>
                        </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3 contactFormInput" controlId="exampleForm.ControlComment">
                        <Form.Label>Commentaire</Form.Label>
                        <Form.Control className='PartenaireInput' as="textarea" rows={7} />
                    </Form.Group>

                    <Button className='PartenarshipBtn' type="submit">
                        Envoyer
                    </Button>
                </Form>
                </div>
            </div>
            <div className='PartenarshipFeedback'>
                Voulez-vous laisser un avis ? <Link to='/Avis' className='feedbackRequest'>Allons-y !</Link> 
            </div>
        </div>
        <FooterBottom/>
    </div>
  )
}

export default Partenarship