import React from 'react'
import Table from 'react-bootstrap/Table';
import hasnaoui from '../../assets/image/hasnaoui.webp'
import pigma from '../../assets/image/pigma.webp'
import amuda from '../../assets/image/amouda.webp'
import actif from '../../assets/image/Logo-Actif-Construction.webp'
import mdm from '../../assets/image/MDM_logo.webp'
import enap from '../../assets/image/enap.webp'
import './partenaire.css'

function Partenaire () {
  return (
    <div className='partenairesFullConatainer'>
        <div className='partenaireContainer'>
            <div className='partenairesList'>
            <Table striped bordered hover className='partenaireTable'>
                <tbody>
                    <tr>
                    <td><img src={hasnaoui} alt='' className='partenaireImg'></img></td>
                    <td><img src={amuda} alt='' className='partenaireImg'></img></td>
                    </tr>
                    <tr>
                    <td><img src={pigma} alt='' className='partenaireImg'></img></td>
                    <td><img src={mdm} alt='' className='partenaireImg'></img></td>
                    </tr>
                    <tr>
                    <td><img src={actif} alt='' className='partenaireImg'></img></td>
                    <td><img src={enap} alt='' className='partenaireImg'></img></td>
                    </tr>
                </tbody>
                </Table>
            </div>
            <div className='partenairesText'>
              <div className='partenaireTitre'>Nos Partenaires</div>
              <div className='partenaireDesc'>Nous nous efforçons constamment de vous offrir une qualité de service et de produits irréprochable. Pour ce faire, nous choisissons méticuleusement nos partenaires, qui contribuent à notre engagement envers l'excellence.</div>
            </div>
        </div>
    </div>
  )
}

export default Partenaire