import React from 'react'
import './footer.css'

function FooterBottom(){
  return (
    <div className='footerSection'>
            <div className='bottom-footer'>
                &copy; 2024 Ibeni , Tous Droits Réservés.
            </div>
    </div>
  )
}

export default FooterBottom