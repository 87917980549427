import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import './Produit.css'
import { useMatiereContext } from '../../Api/context/Context';
import { useParams } from 'react-router-dom';
import { addCommandeapi } from '../../Api/Api'
import {jwtDecode} from 'jwt-decode';
import Modal from 'react-bootstrap/Modal';
import Notifycomposant from '../Notification/Notify';



function ProductDetail (props) {
    const [clienId , setClientId] = useState();
    const {products} = useMatiereContext();
    const {id} = useParams();
    const [quantity, setQuantity] = useState(1);
    const [productName ,setProductName] = useState('');
    const [productSKU ,setProductSKU] = useState('');
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [productPic , setProductPic] = useState('');
    const [notification, setNotification] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [typePaiement, setTypePaiement] = useState(1);
    const [nomClient, setNomClient] = useState('');
    const [prenomClient, setPrenomClient] = useState('');
    const [wilaya , setWilaya] = useState('');
    const [numTel, setNumTel] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    // Fonction pour mettre à jour la valeur sélectionnée
    const handleSelectChange = (event) => {
        setTypePaiement(event.target.value);
    };
    useEffect(() => {
        const prod = products.find(product => product.id === id);
        if (prod) {
            setTotalPrice(prod.Prix);
            setProductName(prod.NomProduct);
            setProductSKU(prod.SKU);
            setPrice(prod.Prix / typePaiement);
            setDescription(prod.Description);
            setProductPic(prod.Picture);
        } else {
            // Gérer le cas où l'ID spécifié n'est pas trouvé dans le tableau products
            console.log("Produit non trouvé");
        }
    
        const fetchClient = async () => {
            const token = localStorage.getItem("token");
            const client = jwtDecode(token);
            setClientId(client.id);
            setNomClient(client.nom);
            setPrenomClient(client.prenom);
            setWilaya(client.wilaya);
            setNumTel(client.phone);
            console.log(client.phone)
        };
        fetchClient();


    }, [id, products, typePaiement]); // Ajoutez les dépendances nécessaires à useEffect
    

    const handleQuantityChange = (e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value)) {
            setQuantity(value);
        }
    };

    // État local pour stocker la valeur sélectionnée
        


    const handleSubmitCommandeInfo = async(event)=>{
        event.preventDefault();
        if (
            !productName ||
            !id ||
            !clienId ||
            !typePaiement ||
            !quantity ||
            !totalPrice || !price
          ) {
              console.log(productName);
              console.log(id);
              console.log(clienId);
              console.log(typePaiement);
              console.log(totalPrice);
              console.log(quantity);
            setNotification({
              type: "error",
              message: "Veuillez remplir tous les champs !",
            });
            return;
          }
      
          try {
            const response = await addCommandeapi({
                IdProduct: id,
                IdClient: clienId,
                typePaiement: typePaiement,
                quantity: quantity,
                prixTotale: totalPrice,
                prixPerMonth : price
            });
            handleNotification("success", response);
          } catch (error) {
            console.error(error.response);
            const errorMessage =
              error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Erreur lors de l'ajout de produit";
            handleNotification("error", errorMessage);
          }
        };
      
      
        const handleNotification = (type, message) => {
          setNotification({
            type,
            message,
          });
        };
  return (
    <div className='ProductDetailFullContainer'>
        <div className='ProductDetailContainer'>
            <img className='ProductDetailImg' src={productPic} alt=''></img>
            <div className='ProductDetailContent'>
                <h5 className='ProductName'>{productName}</h5>
                <p className='ProductSKU'>SKU : <span className='ProductSKUSpan'>{productSKU}</span></p>
                <div>
                    <Form className='productQuantityForm'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='productQuantity'>Quantité</Form.Label>
                            <Form.Control 
                                type="number"
                                pattern="[0-9]*"
                                value={quantity}
                                min="1"
                                max="1000"
                                placeholder="Saisissez la quantité"
                                onChange={handleQuantityChange}
                                className='quantiteInput'
                            />
                        </Form.Group>
                    </Form>
                </div>
                <div className='ProductPrice'>Montant à payer : <span className='productPriceSpan'>{price} /Mois</span></div>
                <div className='productModePaiement'>
                    <Form.Label className='ProductPrice'>Payer en : </Form.Label>
                    <Form.Select aria-label="Default select example" className='productTriSelect' value={typePaiement} onChange={handleSelectChange}>
                            <option value={1}>En cash</option>
                            <option value={12}>12 Mois</option>
                            <option value={24}>24 Mois</option>
                            <option value={36}>36 Mois</option>
                            <option value={48}>48 Mois</option>
                            <option value={60}>60 Mois</option>
                    </Form.Select>
                </div>
                <div className='productAccordions'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>DÉTAILS D'ARTICLE</Accordion.Header>
                        <Accordion.Body>
                        {description}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>POLITIQUE D'ÉCHANGE ET DE REMBOURSEMENT</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>INFO DE LIVRAISON</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </div>
                <Button className='ComanderProduitBtn' onClick={handleShow}>Commander</Button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Ajouter un produit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form  className='addProductForm' >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nom :</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={nomClient}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={prenomClient}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Wilaya</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={wilaya}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={numTel}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Nom produit</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={productName}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Type de paiement :</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={typePaiement}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Quantité</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={quantity}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Prix Par Mois</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du produit"
                            disabled
                            name="NomProduct"
                            value={price}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={handleSubmitCommandeInfo}>
                        confirmer
                    </Button>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
        {notification && (
        <Notifycomposant
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  )
}

export default ProductDetail