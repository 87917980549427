import React from 'react'
import CollapsedNavbar from '../../components/navbar/Navbar'
import AccueilCarousel from '../../components/Accueil/Accueil'
import AboutUs from '../../components/AboutUs/AboutUs'
import BestSeller from '../../components/Produit/BestSeller'
import OurServices from '../../components/Services/OurServices'
import Partenaire from '../../components/Partenaires/Partenaire'
import Footer from '../../components/Footer/Footer'

function LandingPage() {
  return (
    <div>
        <CollapsedNavbar/>
        <AccueilCarousel/>
        <AboutUs/>
        <BestSeller/>
        <OurServices/>
        <Partenaire/>
        <Footer/>
    </div>
  )
}

export default LandingPage