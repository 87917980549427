import React from 'react'
import NavbarAdmin from './../../components/Admin/NavbarAdmin';
import BoutiqueAdmin from '../../components/AddProduct/BoutiqueAdmin';
import FooterBottom from '../../components/Footer/FooterBottom';

function BoutiqueAdminPage (){
  return (
    <div>
        <NavbarAdmin/>
        <BoutiqueAdmin/>
        <FooterBottom/>
    </div>
  )
}

export default BoutiqueAdminPage