import React, { useEffect, useState } from 'react'
import { useMatiereContext } from '../../Api/context/Context'
import {jwtDecode} from 'jwt-decode';
import Notifycomposant from "../Notification/Notify";
import { useNavigate } from 'react-router-dom';
import ProductCard from '../Produit/ProductCard';
import './Panier.css'
import { Button } from 'react-bootstrap';
import {apiDeletePanierProduct} from '../../Api/Api';

function Panier () {
  const {panierProducts} = useMatiereContext();
  const [clienId , setClientId] = useState();
  const navigate = useNavigate();
  useEffect(()=>{
      const fetchClient = async () => {
          const token = localStorage.getItem("token");
          const client = jwtDecode(token);
          setClientId(client.id);
        };
        fetchClient();
  })
    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    const Handledelete = async (e, ClientId,ProductId) => {
      e.preventDefault();
      const response = await apiDeletePanierProduct(ClientId,ProductId);
      console.log(response);
    };
  return (
    <div className='PanierFullcontainer'>
      <div className='PanierContainer'>
        <div className='PanierTitle'>Mon panier</div>
        <hr className="PanierLine"/>
        <div className='productPanierConatiner'> 
        {panierProducts.map((prod, index) => {
          return (
            <div className='productPanier'>
              <div className='productPanierHeader'>
                <div className='panierProductSpan'><span>Ajouté le : </span>{formatDate(prod.createdAt)}</div>
                <Button className='panierProductBtn' onClick={(e) => Handledelete(e, clienId, prod.id)}>Supprimer</Button>
              </div>
              <ProductCard key={index} id={prod.id} authent={true} productImg={prod.Picture} productName={prod.NomProduct} productPrice={prod.Prix} />
            </div>
          );
        })}
        </div>
      </div>
    </div>
  )
}

export default Panier