import React from 'react'
import './aboutUs.css'

function AboutUsCard (props) {
  return (
    <div className='AboutUsCardFullContainer'>
        <div className='AboutUsCardContainer'>
            <div className='AboutUsCardIconContainer'>
                {props.Icon}
            </div>
            <div className='AboutUsCardInfo'>
                <div className='AboutUsCardTitle'>{props.titre}</div>
                <div className='AboutUsCardDesc'>{props.description}</div>
            </div>
        </div>
    </div>
  )
}

export default AboutUsCard