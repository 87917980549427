

import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const hasRequiredAccess = ({ requiredRoles, element }) => {
  const token = localStorage.getItem('token');
  const user = token ? jwtDecode(token) : {};
  const userRole = user.role?user.role.toString() : "guest";
const hasAccess = requiredRoles.includes(userRole);
console.log(userRole)
if (hasAccess) {
  return element;
} else {
  console.error('Invalid or empty requiredRoles:', requiredRoles);
  return <Navigate to="/pagenotfound" />;
}
}

export default hasRequiredAccess;
