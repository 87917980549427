import React from 'react'
import DossierCard from './DossierCard'
import img1 from '../../assets/image/img1.jpg'
import img2 from '../../assets/image/img2.jpg'
import img3 from '../../assets/image/img3.jpg'
import img4 from '../../assets/image/img4.jpg'
import women from '../../assets/image/women.png'
import './dossier.css'

function Dossier () {
  const steps = [
    {
      imag : img1,
      titre:"Composer Votre Pack",
      detail:"Confirmer les quantités et les articels dont vous avez besoin."
    },
    {
      imag : img2,
      titre:"Composer et préparer votre dossier",
      detail:"Le dossier vas t'être récupérer par nos livreur a votre demande"
    },
    {
      imag : img3,
      titre:"Traitement & validation de dossier",
      detail:"Notre équipe prépare votre commande et traite votre dossier dans 72 heures"
    },
    {
      imag : img4,
      titre:"livraison du materials",
      detail:"Fixez le rendez-vous qui vous convient pour la livraison de vos matériaux."
    },
  ]
  return (
    <div className='dossierFullConatiner'>
        <div className='dossierContainer'>
            <div className='dossierTitle'>une procédure facile pour avoir vos matériaux par facilité</div>
            <div className='dossierAppraoch'>
              {steps.map((step, index)=>{
                return(
                  <DossierCard dossierImg={step.imag} dossierNum={index+1} dossierTitre={step.titre} dossierDetail={step.detail}/>
                )
              })}
            </div>
            <div className='dossierContent'>
              <div className='dossierTitle'>Liste des documents à fournir pour le financement via le service digital « Ibeni » :</div>
              <div className='dossierContentDetail'>
                <img src={women} alt='' className='dossierContentImg'></img>
                <ul className='dossierPapers'>
                  <li className='dossierPapersItem'>Justificatif d'identité en cours de validité (C.N.I biométrique) (02) deux exemplaires ;</li>
                  <li className='dossierPapersItem'>Acte de naissance (02) deux exemplaires ;</li>
                  <li className='dossierPapersItem'>Justificatif de résidence de moins de 3 mois (Fiche de résidence) (02) deux exemplaires ;</li>
                  <li className='dossierPapersItem'>Une (01) fiche familiale pour les personnes mariées ;</li>
                  <li className='dossierPapersItem'>L'extrait de comptes des trois (3) derniers mois</li>
                  <li className='dossierPapersItem'>Une attestation de travail de moins d'un (01) mois spécifiant le caractère indéterminé du contrat et la titularisation dans le poste occupé ;</li>
                  <li className='dossierPapersItem'>Justificatif de revenus :
                    <ul className='dossierRevenus'>
                      <li className='dossierPapersItem'>Retraités : attestation de perception de retraite.</li>
                      <li className='dossierPapersItem'>Salariés : trois (03) dernières fiches de paie.</li>
                    </ul>
                  </li >
                  <li className='dossierPapersItem'>Un chèque CCP barré ;</li>
                  <li className='dossierPapersItem'>Deux (02) photos</li>
                  <li className='dossierPapersItem'>Une copie de l'échéancier en cas de crédit en cours</li>
                  <li className='dossierPapersItem'>Les bénéficiaires des accords conclus doivent prouver leur affiliation à ces organismes</li>
                </ul>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Dossier