import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './pages/LandingPage/LandingPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import ProtectedRoute from './protectedRoute'
import PartenarshipPage from './pages/PartenarshipPage/PartenarshipPage';
import ContactPage from './pages/ContactPage/ContactPage';
import AvisPage from './pages/AvisPage/AvisPage';
import BoutiquePage from './pages/BoutiquePage/BoutiquePage';
import DossierPage from './pages/DossierPage/DossierPage';
import ProductDetailPage from './pages/ProductDetailPage/ProductDetailPage';
import PanierPage from './pages/PanierPage/PanierPage';
import BoutiqueAdminPage from './pages/BoutiqueAdminPage/BoutiqueAdminPage';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<LandingPage/>}></Route>
      <Route path='/Partenariats' element={<PartenarshipPage/>}></Route>
      <Route path='/Contact' element={<ContactPage/>}></Route>
      <Route path='/Avis' element={<AvisPage/>}></Route>
      <Route path="client">
            {/* Routes for client */}
            <Route
              path="login"
              element={
                <ProtectedRoute
                  element={<Auth />}
                  requiredRoles={["client","admin","guest"]}
                />
              }
            />
            <Route
              path="signup"
              element={
                <ProtectedRoute
                  element={<Auth />}
                  requiredRoles={["client","admin","guest"]}
                />
              }
            />
            <Route
              path="Boutique"
              element={
                <ProtectedRoute
                  element={<BoutiquePage />}
                  requiredRoles={["client"]}
                />
              }
            />
            <Route
              path="DossierAFournir"
              element={
                <ProtectedRoute
                  element={<DossierPage />}
                  requiredRoles={["client"]}
                />
              }
            />
            <Route
              path="ProductDetail/:id"
              element={
                <ProtectedRoute
                  element={<ProductDetailPage />}
                  requiredRoles={["client"]}
                />
              }
            />
            <Route
              path="Panier"
              element={
                <ProtectedRoute
                  element={<PanierPage />}
                  requiredRoles={["client"]}
                />
              }
            />
          </Route>
          <Route path="admin">
            {/* Routes for admin */}
            <Route
              path="dashboard"
              element={
                <ProtectedRoute
                  element={<BoutiqueAdminPage />}
                  requiredRoles={["admin"]}
                />
              }
            />
          </Route>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
