import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import logo from '../../assets/image/IbeniLogo2.svg'
import './footer.css'
import { faClock, faEnvelope, faHeadset, faLocation, faLocationDot } from '@fortawesome/free-solid-svg-icons';

function ContactUs() {
  return (
    <div className='contactUsFullConatiner'>
        <div className='contactUsContainer'>
            <div className='contactUsTotale'>
                <div className='FootercontactInfoConatiner'>
                    <div className='contactUsTitle'>CONTACT INFO</div>
                    <hr className='footerLine'/>
                    <img src={logo} alt='' className='contactUsLogo'></img>
                    <ul className='contactList'>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faLocationDot} className='contactIcon'/>
                            <div className='contactDetail'>Visiter Notre Siège à <br/><span className='contactSpan'>Val Hydra , Alger</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faHeadset} className='contactIcon'/>
                            <div className='contactDetail'>Avez-vous une question? Contactez-nous <br/><span className='contactSpan'>021 67 98 09</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faClock} className='contactIcon'/>
                            <div className='contactDetail'>Les horaires de travail <br/><span className='contactSpan'>Dimanche-Jeudi: 08:30-17:00</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faEnvelope} className='contactIcon'/>
                            <div className='contactDetail'>Contactez-nous via <br/><span className='contactSpan'>hamidadjamil.h@gmail.com</span></div>
                        </li>
                    </ul>
                </div>
                <div className='FootercontactWithUs'>
                    <div className='contactUsTitle'>CONTACTEZ-NOUS</div>
                    <hr className='footerLine'/>
                    <div className='contactUsDesc'>Nos experts vont le resoudre rapidement</div>
                    <div className='formFooter'>
                        <Form className='FootercontactWithUsForm'>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className='footerLabel'>Votre Nom</Form.Label>
                                <Form.Control className='footerInput' type="text" placeholder="Veuillez entrer votre Nom" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className='footerLabel'>Votre Numéro Téléphone</Form.Label>
                                <Form.Control className='footerInput' type="text" placeholder="Veuillez entrer votre numéro de téléphone" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className='footerLabel'>Votre Email</Form.Label>
                                    <Form.Control className='footerInput' type="email" placeholder="Veuillez entrer votre email" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label className='footerLabel'>Type Service</Form.Label>
                                    <Form.Select className='footerInput' defaultValue="Choisir Votre Service">
                                        <option>gros œuvres "dalle et béton "</option>
                                        <option>Estimation de matériaux de construction</option>
                                        <option>Estimation de realisation de Cuisine</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='footerLabel'>Votre Message</Form.Label>
                                <Form.Control className='footerInput' as="textarea" rows={5} placeholder='Entrer votre message...'/>
                            </Form.Group>
                            <Button className='footerBtn' type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ContactUs;

