import React from 'react'
import NavbarAfterAuth from '../../components/navbar/navbarAfterAuth'
import Dossier from '../../components/Dossier/Dossier'
import Footer from '../../components/Footer/Footer'

function DossierPage (){
  return (
    <div>
        <NavbarAfterAuth/>
        <Dossier/>
        <Footer/>
    </div>
  )
}

export default DossierPage