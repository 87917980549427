import React, { useState } from 'react'
import './dossier.css'

function DossierCard (props) {
  const [hovered , setHovered] = useState(false);
  const handleHover = () => {
    setHovered(true);
  }
  const removehandleHover = () => {
    setHovered(false);
  };

  return (
    <div className='dossierCardContainer' onMouseEnter={handleHover} onMouseLeave={removehandleHover}>
        <div className='dossierCardContent'>
            <img className={hovered? 'dossierCardImgHovered' : 'dossierCardImg'} src={props.dossierImg} alt=''></img>
            <div className='dossierCardDetail'>
               <div className={hovered? 'dossierCardNum center' : 'dossierCardNum right'}>{props.dossierNum}</div>
                <div className='dossierCardDetailContent'>
                    <div className='dossierCardDetailTitre'>{props.dossierTitre}</div>
                    <div className='dossierContenu'>
                    {props.dossierDetail}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DossierCard