import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from React Router
import './auth.css'; // Import your CSS file here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import logoEsi from '../../assets/image/IbeniLogo1.svg'
import {Wilaya} from './Wilaya'
import {api} from '../../Api/Api';
import Notifycomposant from '../Notification/Notify';
import Cookies from "js-cookie";
import { useMatiereContext } from "../../Api/context/Context";
import {jwtDecode} from 'jwt-decode';

function Auth() {
  const [client, setClient] = useState({
    Nom: "",
    Prenom: "",
    Wilaya: "",
    Phone: "",
    Email: "",
    Password: "",
    //Photo:"http://localhost:4000/elearning/Images/accounts/user.png"
  });
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [values, setValues] = useState({
    Password: "",
    showPassword: false,
  });

  const { loadData, resetData, loadDataAdmin, loadDataProf } =
    useMatiereContext();
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [signUpMsg , setsignUpMsg] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const isPhoneValid = /^\d+$/.test(client.Phone);
      if (!isPhoneValid) {
        setNotification({
          type: "error",
          message: "Le numero de telephone ne doit contenir que des numero!",
        });
        return;
      }
      const message = await api.signUp(client);
      setsignUpMsg(message);
      setNotification({
        type: "success",
        message,
      });
      setIsSignUpMode(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setNotification({
          type: "error",
          message: err.response.data.message,
        });
      } else {
        setNotification({
          type: "error",
          message: "Erreur lors de l'inscription",
        });
      }
    }
  };

  const onChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
    console.log(client);
  };
  const [isSignUpMode, setIsSignUpMode] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };
  const handleSubmitSignIn = async (e) => {
    e.preventDefault();
    try {
      const message = await api.LogIn(user);

      setNotification({
        type: "success",
        message: "Bienvenue !",
      });
      localStorage.setItem("token", message);
      const client = jwtDecode(message);
      const role = client.role;
      localStorage.setItem("client", JSON.stringify(client));

      resetData();

      if (role === "admin") {
        await loadDataAdmin();
        navigate("/admin/dashboard");
      } else if (role === "client") {
        await loadData(client);
        navigate("/client/Boutique");
      } 
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.message) {
        setNotification({
          type: "error",
          message: err.response.data.message,
        });
      } else {
        console.log("Logging the error", err);
        setNotification({
          type: "error",
          message: "Erreur lors de l'authentification !",
        });
      }
    }
  };

  return (
    <div className={`Authcontainer ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="Authforms-container">
        <div className="signin-signup">
          <form onSubmit={handleSubmitSignIn} className="sign-in-form">
            <h2 className="title">Se Connecter</h2>
            <div className="input-field">
              <FontAwesomeIcon icon={faUser} className="fa-icon"></FontAwesomeIcon>
              <input name="email" onChange={(e) => setUser({ ...user, Email: e.target.value })} type="text" placeholder="Email" />
            </div>
            <div className="input-field">
              <FontAwesomeIcon icon={faLock} className="fa-icon"></FontAwesomeIcon>
              <input name="Password"  type="password" placeholder="mot de passe" onChange={(e) => {
              setUser({ ...user, Password: e.target.value });
            }}/>
            </div>
            <input onClick={console.log(user)} type="submit" value="Se Connecter" className="btnLogin solid" />
            <p className="social-text">Ou bien se connecter via un compte @gmail</p>
            <div className="social-media">
              <Link to="#" className="social-icon">
                <FontAwesomeIcon icon={faEnvelope}  className="fab fa-facebook-f"></FontAwesomeIcon>
              </Link>
            </div>
            {notification && (
              <Notifycomposant
                type={notification.type}
                message={notification.message}
                onClose={() => setNotification(null)}
              />
            )}
          </form>
          <form onSubmit={handleSubmit} className="sign-up-form">
            <h2 className="title">S'inscrire</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input name='Nom' onChange={onChange} type="text" placeholder="Nom" />
            </div>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input name='Prenom' onChange={onChange} type="text" placeholder="Prénom" />
            </div>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input name='Phone' onChange={onChange} type="text" placeholder="Numéro Téléphone" />
            </div>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <select name="Wilaya" onChange={onChange} placeholder="Numéro Téléphone" className='inputSelect-field'>
              <option selected disabled>
                {"Wilaya"}
              </option>
                {Wilaya.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-field">
              <i className="fas fa-envelope"></i>
              <input name='Email' onChange={onChange} type="email" placeholder="Email" />
            </div>
            <div className="input-field">
              <i className="fas fa-lock"></i>
              <input name='Password' onChange={onChange} type="password" placeholder="Mot de passe" />
            </div>
            <input type="submit" onClick={console.log(signUpMsg)} className="btnLogin" value="S'inscrire" />
            <p className="social-text">Ou bien s'inscrire en utilisant le compte @gmail</p>
            <div className="social-media">
            <Link to="#" className="social-icon">
                <FontAwesomeIcon icon={faEnvelope}  className="fab fa-facebook-f"></FontAwesomeIcon>
              </Link>
            </div>
            {notification && (
          <Notifycomposant
            type={notification.type}
            message={notification.message}
            onClose={() => setNotification(null)}
          />
        )}
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <img src={logoEsi} alt='' className='logoAuth'></img>
            <h3 className='BienvenueIbeni'>Bienvenue au site de IBENI</h3>
            <p>
              Connectez-vous et explorez un monde d'opportunités !
            </p>
            <button className="btnLogin transparent" onClick={handleSignUpClick}>
              S'inscrire
            </button>
          </div>
        </div>
        <div className="panel right-panel">
          <div className="content">
            <img src={logoEsi} alt='' className='logoAuth'></img>
            <h3 className='BienvenueIbeni'>L'un de Nous ?</h3>
            <p>
              Faites le premier pas vers une expérience enrichissante en vous inscrivant maintenant.
            </p>
            <button className="btnLogin transparent" onClick={handleSignInClick}>
              Se connecter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
