//import React, { useEffect } from 'react';
import axios from 'axios';
//import { useMatiereContext } from './context/Context';
const baseURL = 'https://ibeni.logikabox.com:4000/ibeni';
const token = localStorage.getItem('token');

const commonHeaders = {
  'Content-Type': 'application/json',
  'token': token
};

const instance = axios.create({
  baseURL,
  headers: commonHeaders,
});

// Fonction pour vérifier si un jeton est expiré
const isTokenExpired = (token) => {
  if (!token) {
    // Aucun jeton
    return true;
  }

  try {
    const decodedToken = parseJwt(token);
    const expirationTime = decodedToken.exp * 1000; // Convertir en millisecondes

    // Comparer avec l'heure actuelle
    return Date.now() >= expirationTime;
  } catch (error) {
    // Une erreur s'est produite lors de l'analyse du jeton
    console.error('Error parsing token:', error);
    return true;
  }
};

// Fonction pour analyser un jeton JWT
const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64));
  return JSON.parse(jsonPayload);
};

// Flags
let shouldStopRequests = false;
let isLoggedIn = false;
 export const checkToken = async () => {
  const token = localStorage.getItem('token');

  if ( isTokenExpired(token)) {
    isLoggedIn = false;
    localStorage.removeItem('token');
    localStorage.removeItem('client');
    redirectToLogin();
  } else {
    isLoggedIn = true;
  }
};

// Intercepteur de demande
instance.interceptors.request.use(
  async (config) => {
    if (shouldStopRequests) {
      return Promise.reject(new Error('Requests are stopped due to unauthorized access.'));
    }

    checkToken();

    if (isLoggedIn) {
      config.headers['token'] = localStorage.getItem('token');

    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponse
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._ignore401) {
      originalRequest._ignore401 = true;
      console.log('Unauthorized access. Redirecting to login page...');
      redirectToLogin();
      return Promise.resolve();
    }

    return Promise.reject(error);
  }
);

function redirectToLogin() {
  if (!window.location.href.includes('/Partenariats')&&!window.location.href.includes('/client/Login')  &&!window.location.href.includes('/')&&!window.location.href.includes('/Contact') ) {
    window.location.href = '/client/Login';
  }
}

// Fonction de connexion
export const LogIn = async (client) => {
  try {
    const response = await instance.post('/', client,{
      headers: {
        'Content-Type': 'application/json',
        'ExcludeAuthorization': true, 
      },
    });
    console.log(response.data);
    await checkToken(true);
    
    return response.data;
  } catch (err) {
    throw err;
  }
};


export const signUp = async (client) => {
  try {
    console.log(client);
    const response = await axios.post(`${baseURL}/clientservices/Signup`, client,
    {headers: {
    'Content-Type': 'application/json',
      'ExcludeAuthorization': true, 
    },
  });
    return response.data.message;
  } catch (err) {
    throw err;
  }
};
export const LogOut = async () => {

  try {
    const token = localStorage.getItem('token'); // Get the token from localStorage or your preferred storage
    if (!token) {
      throw new Error('Token not found');
    }
    //const tokenjson = JSON.parse(token);
    const config = {
      headers: {
        //toke
        'token': token, 
      },
    };

    const response = await instance.post('/Logout', config);
    localStorage.removeItem('token');
    localStorage.clear();
  } catch (error) {
    console.error('Error during logout:', error);
    //throw error;
  }
}
// export const refreshAccessToken = async (refreshToken) => {
//   try {
//     const response = await instance.post('/refreshToken', { refreshToken });

//     if (response && response.data && response.data.accessToken) {
//       return response.data.accessToken;
//     } else {
//       console.error('La réponse de rafraîchissement du jeton est invalide :', response);
//       throw new Error('Réponse de rafraîchissement du jeton invalide');
//     }
//   } catch (error) {
//     console.error('Erreur lors du rafraîchissement du jeton :', error);
//     throw error;
//   }
// };


//PanierProducts:
export const getPanierProducts = async (ClientId) => {
  try {
    const response = await instance.get(`/panierServices/GetProductByPanier/${ClientId}`);
    if (response && response.data) {
      return response.data;
    } else {
      console.log('Response does not contain data property');
    }
  } catch (err) {
    throw err;
  }
}
export const addPanierProductapi = async (panierData) => {
  try {
    const token = localStorage.getItem('token');
    
    const requestBody = {
      IdClient: panierData.IdClient,
      IdProduct: panierData.IdProduct
    };

    const response = await instance.post('/panierservices/AddPanierProduct', requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'token': token, 
      },
    });

    return response.data.message;
  } catch (error) {
    console.error("Erreur lors de l'ajout du produit :", error);
    throw error;
  }
};



export const apiDeletePanierProduct = async (ClientId , ProductId) => {
  try {
    const response = await instance.delete(`/panierServices/DeletePanierProduct/${ClientId}/${ProductId}`);
    window.location.reload();
    return response.data.message;
  } catch (err) {
    window.location.reload();
    throw err;
  }
};

//Dashboard
export const getNumberOfClientsAndProfs = async () => {
  try {
    const response = await instance.get('/statisticsservices/Getnumberclientprof');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRevenues = async (numberOfDays) => {
  try {
    const response = await instance.get(`/statisticsservices/Getrevenues?numberOfDays=${numberOfDays}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNumberOfOnlineUsers = async (userType) => {
  try {
    const response = await instance.get(`/statisticsservices/Getnumberonline`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getMonthlyOrders = async (numberOfDays) => {
  try {
    const response = await instance.get(`/statisticsservices/monthlyorders`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


//notifications
export const getNotifications = async () => {
  try {
    const response = await instance.get(`/notificationServices/GetAllNotifcations`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
//Commandes
export const getCommandes = async () => {
  try {
    const response = await instance.get(`/commandeServices/GetAllCommande`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getClientCommandes = async (IdClient) => {
  try {
    const response = await instance.get(`/commandeServices/GetClientCommandes/${IdClient}`);

    if (response && response.data) {
    const clientCommandes = response.data;

    const clientCommandesWithCoursesInfo = await Promise.all(
      clientCommandes.map(async (commande) => {

        return {
          ...commande,
        };
      })
    );
    return clientCommandesWithCoursesInfo;
  } else {
    console.log('Response does not contain data property');
    return [];
  }
  } catch (error) {
    throw error;
  }
};

export const validateCommande = async (CommandeId) => {
  try {
    const response = await instance.put(`/commandeServices/ValidateCommande/${CommandeId}`);
    window.location.reload()
    return response.data;

  } catch (error) {
    window.location.reload()
    throw error;
  }
};
export const deleteCommande=async (CommandeId) => {
  try {
    const response = await instance.delete(`/commandeServices/DeleteCommande/${CommandeId}`);
    window.location.reload()
    return response.data;

  } catch (error) {
    window.location.reload()
    throw error;
  }
};
export const addCommandeapi = async (data, token) => {
  try {
    const requestBody = {
      IdProduct: data.IdProduct,
      IdClient: data.IdClient,
      typePaiement : data.typePaiement,
      quantity : data.quantity ,
      prixTotale : data.prixTotale,
      prixPerMonth : data.prixPerMonth
    };
    console.log(data)
    const response = await instance.post('/commandeServices/AddCommande', requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'token': token,
      },
    });
    // window.location.reload()
    return response.data.message;
  } catch (error) {
    // window.location.reload() 
    console.error("Erreur lors de l'ajout du pack :", error);
    throw error;
  }
};
//Produits
/*export const lesProduitsLesPlusVendues=async () =>{
    try {
      const response = await instance.get('/productServices/getProductsLesPlusVendus');
      if (response && response.data) {
      const products = response.data;
      const packsWithCoursesInfo = await Promise.all(
        products.map(async (product) => {
          
          return {
            ...product,
          };
        })
      );
      return packsWithCoursesInfo;
    } else {
      console.log('Response does not contain data property');
      return [];
  
    }
    } catch (err) {
      throw err;
    }
  }*/
export const getAllProducts = async () => {
  try {
    const response = await instance.get(`/productServices/GetAllProducts`);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const getProduct = async (ProductId) => {
    try {
        const response = await instance.get(`/productServices/GetProduct/${ProductId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
}
export const deleteAProduct = async (ProductId) => {
  try {
    const response = await instance.delete(`/Profservices/DeleteProf/${ProductId}`);
    window.location.reload()
    return response.data

  } catch (e) {
    window.location.reload()
    throw e;
  }
}
export const addProductapi = async (data) => {
  try {
    const formData = new FormData();
    formData.append("NomProduct", data.NomProduct);
    formData.append("SKU", data.Sku);
    formData.append("Prix", data.Prix);
    formData.append("Description", data.Description);
    formData.append("Picture",data.Picture);
    const response = await instance.post('/productServices/AddProduct', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'token': token, 
      },
    });
    window.location.reload()
    return response.data.message;
  } catch (error) {
    // window.location.reload() 
    console.error("Erreur lors de l'ajout du produit :", error);
    throw error;
  }
};

//Commentaires:
export const addCommentaire = async (IdClient, commentaire) => {
  try {
    const response = await instance.post(`/commentaireServices/AddCommentaire/${IdClient}`, { Commentaire: commentaire });
    window.location.reload();
    return response.data.message;
 
  } catch (error) {
    window.location.reload();
    throw error;
  }
};
export const GetCommentairesByClient = async (IdClient) => {
  try {
    const response = await instance.get(`/commentaireServices/GetCommentsByIdClient/${IdClient}`);
    return response.data;  
  } catch (error) {
    throw error;
  }
};
export const getAllComments = async () => {
    try {
      const response = await instance.get(`/commentaireServices/GetAllCommentaires`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
//resetpassword
export const resetPassword = async (Email) => {
  try {
    const response = await instance.post('/clientservices/Resetpassword', { Email });
    return response.data.message;
  } catch (error) {
    throw error;
  }
};
export const resetPasswordinputs = async (resetToken, newPassword) => {

  try {
    const response = await instance.post(`/clientservices/Resetpassword2/${resetToken}`, { newPassword });
    return response.data;
  } catch (error) {
    throw error;
  }
}
//profiles

export const updateAdminProfile = async (adminId, updatedAdminData) => {
  try {
    const response = await instance.put(`/adminServices/UpdateAdmin/${adminId}`, updatedAdminData);
    return response.data.message;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du profil administrateur :', error);
    throw error;
  }
};



export const api = {
  signUp,
  LogIn,
  LogOut,
  getPanierProducts,
  addPanierProductapi,
  apiDeletePanierProduct,
  getCommandes,
  getClientCommandes,
  validateCommande,
  deleteCommande,
  resetPasswordinputs,
  resetPassword,
  checkToken,
  updateAdminProfile,
  getAllProducts,
  deleteAProduct,
  getProduct,
  addProductapi,
  addCommentaire,
  GetCommentairesByClient,
  getAllComments
  // refreshAccessToken
};
