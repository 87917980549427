import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import ProductCard from "./ProductCard";
import product1 from '../../assets/image/product1.webp'
import product2 from '../../assets/image/product2.webp'
import product3 from '../../assets/image/product3.webp'
import product4 from '../../assets/image/product4.webp'

function BestSeller() {
    const data =[
        {
            productImg:product1,
            productName:'Ciment',
            productPrice:'4500'
        },
        {
            productImg:product2,
            productName:'Ciment colle',
            productPrice:'5000'
        },
        {
            productImg:product3,
            productName:'Enduit',
            productPrice:'2000'
        },
        {
            productImg:product4,
            productName:'Peinture',
            productPrice:'4000'
        },
    ]
  return (
    <div className="bestSellerFullContainer">
        <div className="bestSellerTitle">Les produits les plus vendus</div>
        <hr className="bestSellersLine"/>
        <div className="bestSellerConatiner">
            {data.map((product, index)=>{
                return(
                    <ProductCard key={index} productImg={product.productImg} productName={product.productName} productPrice={product.productPrice} />
                )
            })}
        </div>
    </div>
  );
}

export default BestSeller;
