import React, { createContext, useContext, useState, useEffect } from 'react';
import { getPanierProducts, getCommandes,getAllProducts,
  getProduct ,GetCommentairesByClient, getClientCommandes,getAllComments} from '../Api';
  
import {jwtDecode} from 'jwt-decode';


const MatiereContext = createContext();

export const MatiereProvider = ({ children }) => {
  const [panierProducts, setPanierProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [MostSelled,SetMostSelled]=useState([]);
  const [commandes,setCommandes]=useState([]);
  const [comments,setComments]=useState([]);
  const [clientCommandes,setClientcommandes]=useState([]);
  const token = localStorage.getItem('token');
  let decodedToken = '';
  console.log(token);
  let IdClient = '';
  
  if (token) {
    try {
      decodedToken = jwtDecode(token);
      IdClient = decodedToken.id;
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
  /*
  useEffect(() => {
    const fetchProductsvendus = async () => {
      try {
        const productsData = await  lesProduitsLesPlusVendues();
        SetMostSelled(productsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des packs les plus vendues :', error);
      }
    }; 
    fetchProductsvendus();
  }, []);*/

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const commentsData = await  getAllComments();
        setComments(commentsData);
        console.log(comments)
      } catch (error) {
        console.error('Erreur lors de la récupération des packs les plus vendues :', error);
      }
    }; 
    fetchComments();
  }, []);

  const resetData = () => {
    setProducts([]);
    setPanierProduct([]);
    setCommandes([]);
    setClientcommandes([]);
  };
  const loadData = async (client) => {
    try {
      const productsData = await getAllProducts();
      const panierProductData = await getPanierProducts(client.id);
      const clientCommandesData = await getClientCommandes(client.id);
      if(productsData){
      setProducts(productsData);
      console.log(products);
      }else {
        console.log('Response does not contain data property');
        return [];
      }
      if(panierProductData.length > 0){
        setPanierProduct(panierProductData);
        console.log(panierProducts);
      }else {
        console.log('Response does not contain data property');
        return [''];
      }
      if(clientCommandesData.length > 0){
        setClientcommandes(clientCommandesData);
      }
     else {
      console.log('Response does not contain data property');
      return [];
    }
    } catch (error) {
      console.error('Erreur lors du chargement des données :', error);
    }
  };
  useEffect(() => {
    const client = JSON.parse(localStorage.getItem("client"));
    if (client && client.role === "admin") {
      loadDataAdmin();
    }
  }, []);
  useEffect(() => {
    const client = JSON.parse(localStorage.getItem("client"));
    if (client && client.role === "client") {
      loadData(client);
    }
  }, []);
  
  const  loadDataAdmin=async ()=>{
    const productsData = await getAllProducts();
    const commandesData = await getCommandes();
  if(productsData){
    setProducts(productsData);
  }else {
    console.log('Response does not contain data property');
    return [];
  }
  if(commandesData){
    setCommandes(commandesData);
  }else {
    console.log('Response does not contain data property');
    return [];
  }
  }
  const value = {
    products,
    commandes,
    panierProducts,
    clientCommandes,
    comments,
    MostSelled,
    loadData ,
    resetData,
    loadDataAdmin,
  };

  return (
    <MatiereContext.Provider value={value}>
      {children}
    </MatiereContext.Provider>
  );
};

export const useMatiereContext = () => {
  const context = useContext(MatiereContext);
  if (!context) {
    throw new Error('useMatiereContext must be used within a MatiereProvider');
  }
  return context;
};
