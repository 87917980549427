import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Produit.css'
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import VerticallyCenteredModal from '../Modal/VerticallyCenteredModal';
import { addPanierProduct, addPanierProductapi} from './../../Api/Api';
import {jwtDecode} from 'jwt-decode';
import Notifycomposant from "../Notification/Notify";

function ProductCard(props) {
    const [modalShow, setModalShow] = useState(false);
    const textModel = "Merci d'accéder à votre compte pour pouvoir ajouter Nos produits a votre panier. Si vous ne possedez pas d'un compte , c'est le moment de s'inscrire !"
    const [hovered, setHovered] = useState(false);
    const [authentifie,setAuthentifie] = useState(false);
    const [idClient , setIdClient] = useState('');
    const [idProduct , setIdProduct] = useState('');
    const [notification, setNotification] = useState(null);

    const navigate = useNavigate();
    useEffect(()=>{
        if(props.authent){
            setAuthentifie(true);
        }
        setIdProduct(props.id);
        const fetchClient = async () => {
            const token = localStorage.getItem("token");
            const client = jwtDecode(token);
            setIdClient(client.id);
          };
          fetchClient();
    })

    const handleMouseEnter = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }
    const handleSubmitPanierInfo = async (event) => {
    
        if (
          !idClient ||
          !idProduct 
        ) {
          setNotification({
            type: "error",
            message: "Veuillez remplir tous les champs !",
          });
          return;
        }
    
        try {
            const response = await addPanierProductapi({
                IdClient: idClient,
                IdProduct:  idProduct,
            });
          handleNotification("success", response);
        } catch (error) {
          console.error(error.response);
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Erreur lors de l'ajout de produit";
          handleNotification("error", errorMessage);
        }
      };

      const handleNotification = (type, message) => {
        setNotification({
          type,
          message,
        });
      };
      
  return (
    <div className="ProductCardFullContainer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="ProductCardConatiner">
            <div className="ProductCardImgC">
                <img src={props.productImg} alt="" className="productImg" style={{transform: `scale(${hovered ? 1.1 : 1})`, transition: 'transform 0.5s'}}></img>
                <ul className="productBtnList" style={{ display: hovered ? 'block' : 'none' }}>
                    <li><Button className="productBtn" onClick={authentifie ? ()=>{navigate(`/client/ProductDetail/${props.id}`)}:() => setModalShow(true)}><FontAwesomeIcon icon={faEye} style={{color:'black'}}></FontAwesomeIcon></Button></li>
                    <li><Button onClick={authentifie ? handleSubmitPanierInfo : () => setModalShow(true)} className="productBtn"><FontAwesomeIcon icon={faCartPlus} style={{color:'black'}}></FontAwesomeIcon></Button></li>
                </ul>
                <VerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    TextModel={textModel}
                    />
            </div>
            <div className="ProductCardInfo">
                <div className="ProductTitle">{props.productName}</div>
                <div className="ProductPrice">{props.productPrice} DA</div>
            </div>
        </div>
        {notification && (
        <Notifycomposant
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
}

export default ProductCard;