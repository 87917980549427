import React from 'react'
import NavbarAfterAuth from '../../components/navbar/navbarAfterAuth'
import ProductDetail from '../../components/Produit/ProductDetail'
import Footer from '../../components/Footer/Footer'

function ProductDetailPage (){
  return (
    <div>
        <NavbarAfterAuth/>
        <ProductDetail/>
        <Footer/>
    </div>
  )
}

export default ProductDetailPage