import React, { useState } from 'react'
import ProductCard from '../Produit/ProductCard'
import produit from '../../assets/image/product2.webp'
import boutique from '../../assets/image/boutique.webp'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import './boutique.css'
import NavbarAfterAuth from '../navbar/navbarAfterAuth';
import { useMatiereContext } from "../../Api/context/Context";
import Pagination from '../Pagination/Pagination';

function Boutique () {
    const { products } = useMatiereContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [priceFilter, setPriceFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const filteredProducts = products.filter(product => {
        const passSearchFilter = product.NomProduct.toLowerCase().includes(searchTerm.toLowerCase());
        const passPriceFilter = priceFilter.length === 0 || priceFilter.includes(product.Prix);
        const passCategoryFilter = categoryFilter.length === 0 || categoryFilter.includes(product.NomProduct);
        return passSearchFilter && passPriceFilter && passCategoryFilter;
    });
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const donnees = filteredProducts;
  const totalPages = [];
  for (let i = 1; i <= Math.ceil(donnees.length / itemsPerPage); i++) {
    totalPages.push(i);
  }
  const currentItem = donnees.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckboxChange = (type, value) => {
        if (type === 'price') {
            if (!priceFilter.includes(value)) {
                setPriceFilter([...priceFilter, value]);
            } else {
                setPriceFilter(priceFilter.filter(filter => filter !== value));
            }
        } else if (type === 'category') {
            if (!categoryFilter.includes(value)) {
                setCategoryFilter([...categoryFilter, value]);
            } else {
                setCategoryFilter(categoryFilter.filter(filter => filter !== value));
            }
        }
    };

    
  return (
    <div className='BoutiqueFullContainer'>
        <div className='BoutiqueContainer'>
            <div className='BoutiqueFiltrageBar'>
                <Form className='boutiqueFilterForm'>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Label>Prix</Form.Label>
                        <Form.Check type="checkbox" label="<10000" onChange={() => handleCheckboxChange('price', '<10000')}/>
                        <Form.Check type="checkbox" label=">10000 et <25000" onChange={() => handleCheckboxChange('price', '>10000 et <25000')}/>
                        <Form.Check type="checkbox" label=">25000 et <60000" onChange={() => handleCheckboxChange('price', '>25000 et <60000')}/>
                        <Form.Check type="checkbox" label=">60000" onChange={() => handleCheckboxChange('price', '>60000')}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Label>Categorie</Form.Label>
                        <Form.Check type="checkbox" label="Peinture" onChange={() => handleCheckboxChange('category', 'Peinture')}/>
                        <Form.Check type="checkbox" label="Ciment" onChange={() => handleCheckboxChange('category', 'Ciment')}/>
                        <Form.Check type="checkbox" label="Enduit" onChange={() => handleCheckboxChange('category', 'Enduit')}/>
                        <Form.Check type="checkbox" label="Ciment Colle" onChange={() => handleCheckboxChange('category', 'Ciment Colle')}/>
                    </Form.Group>
                </Form>
            </div>
            <div className='BoutiqueProductsConatiner'>
                <img src={boutique} alt='' className='BoutiqueImg'></img>
                <Form className="d-flex BoutiqueForm2">
                    <Form.Control
                    type="search"
                    className="me-2"
                    aria-label="Search"
                    placeholder="Rechercher un produit"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                    <Button variant="outline-success">Search</Button>
                </Form>
                <div className='BoutiqueProductsInfo'>
                    <div className='ProductsNumber'>25 articles</div>
                    <div className='ProductsTrie'>
                        <Form.Label>Trié par : </Form.Label>
                        <Form.Select aria-label="Default select example" className='productTriSelect'>
                            <option value="Recommandé">Recommandé</option>
                            <option value="Plus récent">Plus récent</option>
                            <option value="bas à élevé">Prix (bas à élevé)</option>
                            <option value="élevé à bas">Prix (élevé à bas)</option>
                            <option value="A-Z">Nom (A-Z)</option>
                            <option value="Z-A">Nom (Z-A)</option>
                        </Form.Select>
                    </div>
                </div>
                <div className='BoutiqueProducts'>
                {currentItem.map((prod, index) => {
                            return (
                                <ProductCard key={index} id={prod.id} authent={true} productImg={prod.Picture} productName={prod.NomProduct} productPrice={prod.Prix} />
                            );
                        })}
                </div>
                <div style={{ direction: "rtl" }}>
                    <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    ></Pagination>
          </div>
            </div>
        </div>
    </div>
  )
}

export default Boutique