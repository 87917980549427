import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AboutUsCard from './AboutUsCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faGear, faGears, faMagnifyingGlassDollar, faMicrochip, faMosque } from '@fortawesome/free-solid-svg-icons';

function AboutUs() {
  return (
    <div className='aboutUsFullContainer'>
      <div className='aboutUsContainer'>
        <Container fluid>
          <Row>
            <Col xs={12} sm={7} md={5} lg={4}><AboutUsCard Icon={<FontAwesomeIcon icon={faGauge} className='AboutUsCardIcon'/>} titre='Choix Flexible' description='Notre plateforme vous offre une flexibilité totale pour répondre à vos besoins de construction changeants.' /></Col>
            <Col xs={12} sm={7} md={5} lg={4}><AboutUsCard Icon={<FontAwesomeIcon icon={faMosque} className='AboutUsCardIcon'/>} titre='Conforme au Charia' description='Nous garantissons un achat par facilité respecte les principes de la Charia.'/></Col>
            <Col xs={12} sm={7} md={5} lg={4}><AboutUsCard Icon={<FontAwesomeIcon icon={faMagnifyingGlassDollar} className='AboutUsCardIcon'/>} titre='Économie de coûts' description=" Profitez d'économies significatives sur vos projets de construction grâce à notre plateforme."/></Col>
            <Col xs={12} sm={7} md={5} lg={6}><AboutUsCard Icon={<FontAwesomeIcon icon={faMicrochip} className='AboutUsCardIcon'/>} titre='100% Numérique' description=" De l'achat de matériaux à la coordination des services, tout se fait facilement et efficacement grâce à notre interface numérique intuitive"/></Col>
            <Col xs={12} sm={7} md={5} lg={6}><AboutUsCard Icon={<FontAwesomeIcon icon={faGears} className='AboutUsCardIcon'/>} titre='Solution Rapide' description='Trouvez et achetez rapidement des matériaux et des services, tout en maintenant votre projet sur la bonne voie et en respectant les délais.'/></Col>
          </Row>
          <Row>
            
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AboutUs;
