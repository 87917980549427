import React from 'react'
import CollapsedNavbar from '../../components/navbar/Navbar'
import Contact from '../../components/Contact/Contact'
import FooterBottom from '../../components/Footer/FooterBottom'

function ContactPage (){
  return (
    <div>
        <CollapsedNavbar/>
        <Contact/>
        <FooterBottom/>
    </div>
  )
}

export default ContactPage