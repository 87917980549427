import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./notify.css";

const Notifycomposant = ({ type, message, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  
  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{type === "success" ? "عملية ناجحة" : "!!! عذرا"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`popUp-container ${type === "success" ? "success" : "error"}`}>
          {message}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Notifycomposant;
