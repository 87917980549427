import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import IbeniLogo from '../../assets/image/IbeniLogo2.svg';
import Button from 'react-bootstrap/Button';
import './navbar.css'

function CollapsedNavbar() {
  return (
    <div className='NavbarLandingContainer'>
      <Navbar collapseOnSelect expand="lg" className=" navbarFullContainer">
        <Container className='navbarContainer'>
          <Navbar.Brand href="#home"><img src={IbeniLogo} alt='' className='logoImg'></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto navbarItems">
                  <Nav.Link href="/" className='navbarItem'>Accueil</Nav.Link>
                  <Nav.Link href="/Partenariats" className='navbarItem'>Partenariats</Nav.Link>
                  <Nav.Link href="/Contact" className='navbarItem'>Contact</Nav.Link>
                  <Nav.Link href="/client/Login"><Button className='NavbarBtn'>S'identifier</Button></Nav.Link>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default CollapsedNavbar;


/**
 * 
                <Nav.Link href="/Boutique" className='navbarItem'>Boutique</Nav.Link>
                <Nav.Link href="/Boutique" className='navbarItem'>Dossier à fournir</Nav.Link>
 */