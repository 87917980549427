import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import logo from '../../assets/image/IbeniLogo2.svg'
import { faClock, faEnvelope, faHeadset, faLocation, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import './contact.css'
import'../Footer/footer.css'

function Contact () {
  return (
    <div className='ContactFullConatiner'>
        <div className='ContactContainer'>
        <div className='contactDetailConatiner'>
                    <div className='contactUsTitre'>CONTACT INFO</div>
                    <hr className='ContactLine'/>
                    <img src={logo} alt='' className='contactUsLogo'></img>
                    <ul className='contactList'>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faLocationDot} className='contactIconC'/>
                            <div className='contactDetailLabel'>Visiter Notre Siège à <br/><span className='contactSpanC'>Val Hydra , Alger</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faHeadset} className='contactIconC'/>
                            <div className='contactDetailLabel'>Avez-vous une question? Contactez-nous <br/><span className='contactSpanC'>021 67 98 09</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faClock} className='contactIconC'/>
                            <div className='contactDetailLabel'>Les horaires de travail <br/><span className='contactSpanC'>Dimanche-Jeudi: 08:30-17:00</span></div>
                        </li>
                        <li className='contactItem'>
                            <FontAwesomeIcon icon={faEnvelope} className='contactIconC'/>
                            <div className='contactDetailLabel'>Contactez-nous via <br/><span className='contactSpanC'>hamidadjamil.h@gmail.com</span></div>
                        </li>
                    </ul>
                </div>
                <div className='contactWithUsContent'>
                    <div className='contactUsTitre'>CONTACTEZ-NOUS</div>
                    <hr className='ContactLine'/>
                    <div className='contactUsDesc'>Nos experts vont le resoudre rapidement</div>
                    <div className='formFooter'>
                        <Form className='FootercontactWithUsForm'>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className='ContactLabel'>Votre Nom</Form.Label>
                                <Form.Control className='ContactInput' type="text" placeholder="Veuillez entrer votre Nom" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className='ContactLabel'>Votre Numéro Téléphone</Form.Label>
                                <Form.Control className='ContactInput' type="text" placeholder="Veuillez entrer votre numéro de téléphone" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label className='ContactLabel'>Votre Email</Form.Label>
                                    <Form.Control className='ContactInput' type="email" placeholder="Veuillez entrer votre email" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label className='ContactLabel'>Type Service</Form.Label>
                                    <Form.Select className='ContactInput' defaultValue="Choisir Votre Service">
                                        <option>gros œuvres "dalle et béton "</option>
                                        <option>Estimation de matériaux de construction</option>
                                        <option>Estimation de realisation de Cuisine</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label className='ContactLabel'>Votre Message</Form.Label>
                                <Form.Control className='ContactInput' as="textarea" rows={5} placeholder='Entrer votre message...'/>
                            </Form.Group>
                            <Button className='ContactBtn' type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Contact