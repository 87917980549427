import React from 'react'
import ContactUs from './ContactUs'
import FooterBottom from './FooterBottom'

function Footer () {
  return (
    <div className='footerContainer'>
        <ContactUs/>
        <FooterBottom/>
    </div>
  )
}

export default Footer